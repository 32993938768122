import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const ContactWrapper = styled.section`
  background: #f9f4ed;
  padding: ${props => (props.landingPage ? "0px " : "80px 0")};
  overflow: hidden;

  @media (max-width: 990px) {
    padding: ${props => (props.landingPage ? "0px " : "60px 0")};
  }
  @media (max-width: 767px) {
    padding: ${props => (props.landingPage ? "0px " : "64px 0 64px 0")};
  }

  a {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: ${themeGet("colors.secondaryTextColor", "#297E7F")};
  }
`

export const ContentWrapper = styled.div`
  flex-wrap: wrap;
  @media only screen and (max-width: 768px) {
    margin-top: -30px;
  }

  .react-reveal {
    margin-bottom: 36px;
    &:nth-child(odd) {
    }
    @media only screen and (max-width: 768px) {
      &:nth-child(odd) {
      }
    }
    @media only screen and (max-width: 767px) {
      flex: 0 0 100%;
    }
    @media only screen and (max-width: 440px) {
      margin-bottom: 24px;
    }
  }
`

export const ContactFromWrapper = styled.div`
  h2 {
    margin-bottom: 40px;
    @media only screen and (max-width: 568px) {
      margin-bottom: 20px;
    }
  }

  .order-details {
    margin-bottom: 40px;
    @media only screen and (max-width: 768px) {
      margin-bottom: 70px;
    }

    p {
      line-height: 26px;
    }
  }

  /* Form
––––––––––––––––––––––––––––––––– */
  label,
  label.react-reveal {
    font-size: 16px;
    display: flex;
    margin-bottom: 10px;
  }

  input[type="email"],
  input[type="text"],
  input[type="number"] {
    font-family: "Mulish";
    padding: 18px 16px;
    font-size: 16px;
    border: 1px solid #767474;
    background: transparent;
    color: ${themeGet("colors.textColor", "#333333")};
    border-radius: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  select {
    z-index: 10;
    position: relative;
    background: transparent;
    font-family: "Mulish";
    padding: 18px 16px;
    border: 1px solid #767474;
    font-size: 16px;
    color: ${themeGet("colors.textColor", "#333333")};
    border-radius: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .select-wrapper {
    position: relative;
  }

  .select-wrapper::after {
    content: "▼";
    font-size: 12px;
    top: 20px;
    right: 16px;
    position: absolute;
  }

  input[type="email"]::placeholder,
  input[type="text"]::placeholder,
  input[type="number"]::placeholder,
  select,
  textarea::placeholder {
    color: ${themeGet("colors.textColor", "#333333")};
  }

  input[type="email"],
  input[type="text"],
  input[type="number"],
  textarea,
  select {
    width: 100%;
    margin: 0 auto;
  }

  textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 16px;
    border: 1px solid #767474;
    color: var(--gray-700);
    border-radius: 16px;
    resize: vertical;
    box-sizing: border-box;
    padding: 1.3rem 1rem;
    font-family: "Mulish";
    min-height: 330px;
    margin-bottom: 40px;

    @media only screen and (max-width: 440px) {
      min-height: 120px;
    }
  }

  input:focus,
  select:focus,
  textarea:focus {
    outline: none;
  }

  input:invalid,
  select:invalid,
  textarea:invalid {
    border: 2px solid #ff7d87;
    box-shadow: none;
  }

  label.checkbox {
    display: flex;
    align-items: center;

    &:first-child {
      padding-bottom: 12px;
    }
  }

  input[type="submit"],
  input[type="reset"],
  input[type="button"],
  button {
    font-family: "Mulish";
    padding: 0.5rem 1.25rem;
    font-size: 16px;
    font-weight: 300;
    background-color: ${themeGet("colors.primaryBotton", "#297E7F")};
    color: ${themeGet("colors.white", "#fff")};
    padding: 12px 32px;
    width: 235px;
    border-radius: 50px;
    border: 1px solid ${themeGet("colors.secondaryColor", "#297E7F")};
    transition: background-color 200ms ease-in-out, border 200ms ease-in-out,
      transform 200ms ease-in-out;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
  }

  @media (hover: hover) {
    input[type="submit"]:hover,
    input[type="reset"]:hover,
    input[type="button"]:hover,
    button:hover {
      cursor: pointer;
      background-color: ${themeGet("colors.white", "#fff")};
      border: 1px solid ${themeGet("colors.secondaryColor", "#297E7F")};
      color: ${themeGet("colors.secondaryColor", "#297E7F")};
    }
  }

  button:focus-visible,
  input[type="submit"]:focus-visible,
  input[type="reset"]:focus-visible,
  input[type="button"]:focus-visible {
    outline: none;
  }

  /* Forces a new-line at the end of a code block for layout purposes. */
  pre > code::after {
    content: " ";
  }

  /* Abbreviation
––––––––––––––––––––––––––––––––– */
  abbr {
    text-decoration: none;
    border-bottom: 2px dashed var(--gray-600);
  }

  @media (hover: hover) {
    abbr:hover {
      cursor: help;
    }
  }

  .error {
    font-size: 14px;
    font-weight: 300;
    color: ${themeGet("colors.secondaryTextColor", "#fff")};
    margin-top: 0.25rem;
  }

  button:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }

  button + button {
    margin-left: 0.5rem;
  }
`

export const StyledSelect = styled.select`
  color: var(--blue);
`

export const StyledErrorMessage = styled.div`
  font-size: 14px;
  font-weight: 600;
  padding-left: 16px;
  color: #f93685;
  margin-top: 0.25rem;
`

export default ContactWrapper
