import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const PageTitleWrapper = styled.section`
  padding: 120px 0 0 0;
  overflow: hidden;

  @media (max-width: 990px) {
    padding: 60px 0 0 0;
  }
  @media (max-width: 767px) {
    padding: 40px 0 0 0;
  }

  .react-reveal {
    text-align: center;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
  }

  .alumier {
    max-width: 200px;
    margin: 0 auto 24px;
  }

  button {
    margin: 32px auto 0 auto;
    text-transform: inherit;
    margin-right: 0px;
    color: ${themeGet("colors.white", "#fff")};
    background-color: ${themeGet("colors.secondaryBotton", "#886638")};
    border: 1px solid ${themeGet("colors.secondaryBotton", "#886638")};
    &:hover {
      background-color: #fbfbf9;
      border: 1px solid ${themeGet("colors.secondaryBotton", "#886638")};
      color: ${themeGet("colors.secondaryBotton", "#886638")};
    }
  }

  .info-box {
    background: #f9f4ed;
    max-width: 860px;
    padding: 50px 40px;
    margin: 60px auto 0px;
    border-radius: 32px;

    @media (max-width: 768px) {
      margin: 40px auto 0px;
      padding: 20px;
    }

    p {
      font-family: "bariol-regular";
      font-size: 22px;
      line-height: 32px;
      font-weight: 400;
      @media (max-width: 768px) {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
`

export default PageTitleWrapper
