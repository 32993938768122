import { themeGet } from "@styled-system/theme-get"
import styled from "styled-components"

const SectionWrapper = styled.section`
  width: 100%;
`

export const ProductsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .button-wrapper {
    padding: 0px 16px 22px 16px;
  }

  button {
    width: 100%;
    margin: 32px auto 0 auto;
    text-transform: inherit;
    margin-right: 0px;
    color: ${themeGet("colors.white", "#fff")};
    background-color: ${themeGet("colors.secondaryBotton", "#886638")};
    border: 1px solid ${themeGet("colors.secondaryBotton", "#886638")};
    &:hover {
      background-color: #fbfbf9;
      border: 1px solid ${themeGet("colors.secondaryBotton", "#886638")};
      color: ${themeGet("colors.secondaryBotton", "#886638")};
    }
  }

  .categories-container {
    h2 {
      color: ${themeGet("colors.textColor", "#333333")};
      font-size: 32px;
      line-height: 40px;
      font-weight: 800;
      letter-spacing: 1.28px;
      margin-bottom: 40px;

      @media only screen and (max-width: 1366px) {
        font-size: 30px;
        margin-bottom: 20px;
      }
      @media only screen and (max-width: 991px) {
        font-size: 32px;
      }
      @media only screen and (max-width: 768px) {
        max-width: 100%;
      }
    }

    .products-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .product {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: calc(100% / 3 - 40px);
      box-shadow: 0 0 100px rgba(175, 182, 194, 0.3);
      background-color: ${themeGet("colors.white", "#ffffff")};
      margin-bottom: 72px;
      text-align: left;

      @media only screen and (max-width: 1360px) {
        width: calc(100% / 3 - 30px);
      }
      @media only screen and (max-width: 1220px) {
        width: calc(100% / 2 - 30px);
      }
      @media only screen and (max-width: 767px) {
        width: 100%;
      }
      @media only screen and (max-width: 480px) {
        width: 100%;
      }

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 22px 16px 0px 16px;
        h3 {
          color: ${themeGet("colors.textColor", "#333333")};
          margin: 24px 0 8px;
          font-size: 22px;
          line-height: 30px;
          font-weight: 300;
          letter-spacing: 0.88px;
        }

        p {
          margin: 0;
          font-size: 14px;
          line-height: 28px;
          color: #767474;
          margin-top: 16px;
        }
      }
    }
  }
`

export default SectionWrapper
