import AnchorLink from "react-anchor-link-smooth-scroll"
import Fade from "react-reveal/Fade"
import PropTypes from "prop-types"
import React from "react"

import Alumier from "../../common/assets/image/elegant/alumier.png"
import Box from "../../components/Box"
import Button from "../../components/Button"
import Container from "../../components/UI/Container"
import Heading from "../../components/Heading"
import PageTitleWrapper from "./productPageTitle.style"
import Text from "../../components/Text"

const ProductPageTitle = ({
  sectionHeader,
  sectionSubTitle,
  sectionTitle,
  title,
  sectionText,
  subtitle,
}) => {
  return (
    <PageTitleWrapper>
      <Container width="1740px">
        <Box {...sectionHeader} className="wrapper">
          <Fade up delay={100}>
            <Text
              content={subtitle}
              {...sectionSubTitle}
              style={{ textTransform: "capitalize" }}
            />
            <Heading content={title} as="h1" {...sectionTitle} />
            <img src={Alumier} alt="alumier" className="alumier" />
            <Text
              content="If you are a current patient contact us here for a code to buy Alumier products."
              {...sectionText}
            />
            <Text
              content="If you are not a current patient we suggest a  bespoke regime, for your individual skin concerns."
              {...sectionText}
            />
            <AnchorLink className="smooth_scroll" href="#order" offset="100">
              <Button title="Contact us" className="bookButton" />
            </AnchorLink>
            <div className="info-box">
              <Text
                content="AlumierMD is a true professionally dispensed medical-grade skincare brand dedicated to the latest advancements in Clean Science, and delivering therapeutic outcomes for patients presenting an array of skin conditions and concerns."
                {...sectionText}
              />
            </div>
          </Fade>
        </Box>
      </Container>
    </PageTitleWrapper>
  )
}

// PageTitle style props
ProductPageTitle.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

// PageTitle default style
ProductPageTitle.defaultProps = {
  // section header default style
  sectionHeader: {
    mx: "auto",
    mb: ["60px", "80px", "120px"],
    maxWidth: "980px",
  },
  // sub section default style
  sectionSubTitle: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "18px",
    letterSpacing: "0.72px",
    fontWeight: "300",
    mb: "12px",
    color: "#333333",
    fontFamily: "bariol-regular",
  },
  sectionText: {
    display: "block",
    textAlign: "center",
    fontSize: "16px",
    mb: "4px",
  },

  // section title default style
  sectionTitle: {
    textAlign: "center",
    fontSize: ["32px", "48px"],
    fontWeight: "100",
    letterSpacing: "0.96px",
    lineHeight: ["38px", "56px"],
    mb: ["16px", "24px"],

    color: "#333333",
  },
}

export default ProductPageTitle
