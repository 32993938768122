import * as Yup from "yup"
import Fade from "react-reveal/Fade"
import PropTypes from "prop-types"
import styled from "styled-components"
import React, { useState } from "react"
import { Field, Form, Formik, useField } from "formik"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import Box from "../../components/Box"
import Container from "../../components/UI/Container"
import Heading from "../../components/Heading"
import ContactWrapper, {
  ContactFromWrapper,
  ContentWrapper,
} from "./orderForm.style"

const MyTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  )
}

const OrderForm = ({ formTitle, landingPage }) => {
  const [msgSent, setMsgSent] = useState(false)
  const [selectedSuggestion, setSelectedSuggestion] = useState("sunscreens")

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  function handleSelectChange(event) {
    setSelectedSuggestion(event.target.value)
  }

  const trackButtonEvent = () => {
    trackCustomEvent({
      category: "Order form",
      action: "submit",
      label: "Order form",
    })
  }

  return (
    <ContactWrapper landingPage={landingPage}>
      <Container width="800px">
        <div id="order">
          <Box>
            <ContactFromWrapper>
              {!msgSent ? (
                <Fade up delay={100}>
                  <Heading
                    content={landingPage ? "Get the offer" : "How to purchase"}
                    {...formTitle}
                  />
                  <div className="order-details">
                    <p>
                      Contact us at{" "}
                      <a href="mailto: teagan@elegantclinic.co.uk">
                         teagan@elegantclinic.co.uk
                      </a>{" "}
                      if you are a current customer, for your exclusive access
                      code to Alumier purchases.
                    </p>
                    <p>
                      To buy online, please complete the form below. You will be
                      contacted to arrange a skincare regimen developed
                      specifically for you and will be given an access code to
                      buy Alumier Online.
                    </p>
                    <p>
                      DELIVERY IS FREE FOR ORDERS OVER £50 and £6.95 FOR ORDERS
                      UNDER £50
                    </p>
                  </div>
                </Fade>
              ) : (
                <Fade up delay={100}>
                  <Heading
                    content="Your form has been submitted succesfully"
                    {...formTitle}
                  />
                </Fade>
              )}
              <Formik
                initialValues={{
                  fullName: "",
                  address: "",
                  postalCode: "",
                  productOrder: selectedSuggestion,
                  email: "",
                  phone: "",
                  skinConditions: "",
                }}
                validationSchema={Yup.object({
                  fullName: Yup.string().required("Required"),
                  address: Yup.string().required("Required"),
                  postalCode: Yup.string().required("Required"),
                  phone: Yup.string().required("Required"),
                  skinConditions: Yup.string().required("Required"),
                  email: Yup.string()
                    .email("Invalid email addresss")
                    .required("Required"),
                })}
                onSubmit={(values, actions) => {
                  fetch("/", {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: encode({ "form-name": "orderForm", ...values }),
                  })
                    .then(() => {
                      setMsgSent(true)
                      // alert("Success")
                      actions.resetForm()
                    })
                    .catch(() => {
                      alert("Error")
                    })
                    .finally(() => {
                      actions.setSubmitting(false)
                      setSelectedSuggestion("sunscreens")
                    })
                }}
              >
                <Form name="orderForm" data-netlify={true}>
                  <ContentWrapper>
                    <Fade bottom>
                      <MyTextInput
                        label="Full name *"
                        name="fullName"
                        type="text"
                      />
                    </Fade>
                    <Fade bottom>
                      <MyTextInput
                        label="Address *"
                        name="address"
                        type="text"
                      />
                    </Fade>
                    <Fade bottom>
                      <MyTextInput
                        label="Your Postal code*"
                        name="postalCode"
                        type="text"
                      />
                    </Fade>
                    <Fade bottom>
                      <MyTextInput label="Email *" name="email" type="email" />
                    </Fade>
                    <Fade bottom>
                      <MyTextInput
                        label="Phone number *"
                        name="phone"
                        type="text"
                      />
                    </Fade>
                    <Fade bottom>
                      <label>
                        Which Products would you like to order OR would you like
                        us to suggest after Skin consultation? *
                      </label>
                      <div className="select-wrapper">
                        <Field
                          as="select"
                          name="productOrder"
                          value={selectedSuggestion}
                          onChange={handleSelectChange}
                        >
                          <option value="sunscreens">Sunscreens</option>
                          <option value="cleansers">Cleansers</option>
                          <option value="eye-products">Eye Products</option>
                          <option value="serums">Serums</option>
                        </Field>
                      </div>
                    </Fade>
                    <Fade bottom>
                      <MyTextInput
                        label="Have you got any particular skin conditions that you would like addressed? *"
                        name="skinConditions"
                        type="text"
                      />
                    </Fade>
                  </ContentWrapper>
                  <Fade bottom>
                    <div>
                      <button onClick={trackButtonEvent} type="submit">
                        Send
                      </button>
                    </div>
                  </Fade>
                </Form>
              </Formik>
            </ContactFromWrapper>
          </Box>
        </div>
      </Container>
    </ContactWrapper>
  )
}

// PricesAndOffers style props
OrderForm.propTypes = {
  formTitle: PropTypes.object,
}

OrderForm.defaultProps = {
  // form title default style
  formTitle: {
    textAlign: "center",
    fontSize: ["28px", "40px"],
    fontWeight: "100",
    letterSpacing: "1px",
    mb: "60px",
    color: "#333333",
  },
}

export default OrderForm
