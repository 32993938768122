import React from "react"
import { graphql } from "gatsby"

import Layout from "../containers/Layout"
import OrderForm from "../containers/OrderForm"
import ProductPageTitle from "../containers/ProductPageTitle"
import Products from "../containers/Products"
import SEO from "../components/SEO"
import { ContentWrapper } from "../containers/elegant.style"

const ProductPage = ({ data: { allContentfulProductsMainPage } }) => {
  const allProducts = allContentfulProductsMainPage.edges[0].node.category

  return (
    <>
      <SEO
        title="Products"
        shortDesc="Elegant Beauty Clinic"
        description="Buy Alumier Online from Elegant Beauty Clinic If you are a current patient contact us here for a code to buy Alumier products."
      />
      <Layout>
        <ContentWrapper>
          <ProductPageTitle
            title="Buy Alumier Online from Elegant Beauty Clinic"
            subtitle="Products"
          />
          <Products products={allProducts} />
          <OrderForm />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default ProductPage

export const pageQuery = graphql`
  query ProductsList {
    allContentfulProductsMainPage {
      edges {
        node {
          category {
            category
            products {
              title
              description
              image {
                fluid(quality: 90) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
