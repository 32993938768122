import AnchorLink from "react-anchor-link-smooth-scroll"
import Img from "gatsby-image"
import React from "react"

import Button from "../../components/Button"
import Container from "../../components/UI/Container"
import Heading from "../../components/Heading"
import Text from "../../components/Text"
import SectionWrapper, { ProductsWrapper } from "./products.style"

const ProductsList = ({ products }) => {
  console.log("🚀 ~ file: index.js ~ line 9 ~ BlogList ~ products", products)
  return (
    <SectionWrapper>
      <Container width="1340px">
        <ProductsWrapper>
          {products.map((product, index) => {
            return (
              <div key={index} className="categories-container">
                <Heading as="h2" content={product.category} />
                <div className="products-wrapper">
                  {product.products.map((product, index) => {
                    return (
                      <div key={index} className="product">
                        <div className="content">
                          <Img
                            fluid={product.image.fluid}
                            alt={product.title}
                          />
                          <div>
                            <Heading content={product.title} as="h3" />
                            <Text content={product.description} />
                          </div>
                        </div>
                        <div className="button-wrapper">
                          <AnchorLink
                            className="smooth_scroll"
                            href="#order"
                            offset="100"
                          >
                            <Button title="Order Now" className="orderButton" />
                          </AnchorLink>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </ProductsWrapper>
      </Container>
    </SectionWrapper>
  )
}

export default ProductsList
